/* eslint-disable */
import instance from "@/services/instance.js";

const GetAllPartnerPromotions = (payload) => {
  return instance.instanceToken
    .get("/partnerPromotions/all", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const CreatePartnerPromotions = (payload) => {
  return instance.instanceToken
    .post("/partnerPromotions/create", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const UpdateStatus = (payload) => {
  return instance.instanceToken
    .put("/partnerPromotions/updateStatus", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const GetEnabledPromotions = (payload) => {
  return instance.instanceToken
    .get("/partnerPromotions/allEnabledPromotions", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const DeletePartnerPromotions = (payload) => {
  return instance.instanceToken
    .delete("/partnerPromotions/delete", {data:payload})
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const UploadFile = payload => {
  return instance.instanceFileUpload
    .post("/partnerPromotions/upload", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const ViewPromotion = payload => {
  return instance.instanceToken
    .get(`/partnerPromotions/viewPromotion/${payload.id}`)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

export default {
  getAllPartnerPromotions: GetAllPartnerPromotions,
  createPartnerPromotions: CreatePartnerPromotions,
  updateStatus: UpdateStatus,
  getEnabledPromotions: GetEnabledPromotions,
  deletePartnerPromotions: DeletePartnerPromotions,
  uploadFile: UploadFile,
  viewPromotion: ViewPromotion
};
