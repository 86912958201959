<template>
  <!-- eslint-disable -->
  <v-container fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          color="primary"
          class="px-5 py-3 promotionDetailsContainer"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Partner Promotion View
            </div>
            <div
              class="d-flex flex-row align-center justify-start subtitle-1 font-weight-light"
            >
              <span>This shows the Partner Promotion profile</span>
            </div>
          </template>
          <!-- <div>
            <v-overlay
              :value="overlay"
              opacity="1"
              color="white"
              z-index="0"
              absolute="absolute"
            >
              <v-progress-circular indeterminate size="100" color="primary">
              </v-progress-circular>
            </v-overlay>
          </div> -->
          <v-card-text>
            <v-row
              class="d-flex flex-row align-start justify-space-between"
              v-if="details"
            >
              <!-- promotion details details -->
              <v-col cols="12" class="px-0">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">
                      Partner Promotion Details
                    </h3>
                  </v-col>
                </v-row>
                <!-- individual row for partner promotion details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Partner Name:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.name">{{
                      details.name
                    }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                      >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for partner promotion details -->
                <!-- individual row for partner promotion details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                      >Partner's Official Website:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip bottom v-if="details.website">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a
                            :href="validateUrl(details.website)"
                            target="_blank"
                            >{{ validateUrl(details.website) }}</a
                          >
                        </span>
                      </template>
                      <span>
                        Visit
                        <b>
                          <i>{{ validateUrl(details.website) }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                      >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for partner promotion details -->
                <!-- individual row for partner promotion details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Partner Email:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip bottom v-if="details.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${details.email}`">{{
                            details.email
                          }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ details.name }}</b> via
                        <b>
                          <i>{{ details.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                      >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for partner promotion details -->
                <!-- individual row for partner promotion details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Phone No:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.phoneNo">{{
                      details.phoneNo
                    }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                      >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for partner promotion details -->
                <!-- individual row for partner promotion details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Valid From:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.startDate"
                      >{{ details.startDate.split("T")[0] }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                      >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for partner promotion details -->
                <!-- individual row for partner promotion details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Valid To:</span>
                  </v-col>
                  <v-col cols="8">
                    <span class="subtitle-1 text-left" v-if="details.endDate">{{
                      details.endDate.split("T")[0]
                    }}</span>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                      >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for partner promotion details -->
                <!-- individual row for partner promotion details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                      >Description of Promotion:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.description"
                      >{{ details.description }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                      >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for partner promotion details -->
                <!-- individual row for partner promotion details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Promotion Details:</span>
                  </v-col>
                  <v-col cols="8">
                    <div style="text-align: justify">
                      <span
                        class="subtitle-1 text-left"
                        v-if="details.details"
                        >{{ details.details }}</span
                      >
                      <span
                        class="subtitle-1 text-left platinum--text text--darken-3"
                        v-else
                        >n/a</span
                      >
                    </div>
                  </v-col>
                </v-row>
                <!-- end of individual row for partner promotion details -->
                <!-- individual row for partner promotion details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Document:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-chip
                      class="proof-link ma-0 px-4"
                      color="secondary"
                      label
                      text-color="white"
                      :href="details.documentUploadURL"
                      target="_blank"
                      v-if="details.documentUploadURL"
                    >
                      <span class="subtitle-1">Download</span>
                      <v-icon right>mdi-file-download</v-icon>
                    </v-chip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                      >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for city details -->
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PartnerPromotionsApicalls from "../../../services/partnerPromotions.service";

export default {
  name: "PartnerPromotionView",
  data() {
    return {
      details: null,
      //   overlay: true,
    };
  },
  mounted() {
    // this.overlay = true;
    this.initFn(this.$route.params.id);
  },
  methods: {
    initFn(val) {
      let payload = {
        id: val,
      };
      PartnerPromotionsApicalls.viewPromotion(payload).then((res) => {
        this.details = res.data.details;
      });
    },
    validateUrl(url) {
      if (!url) return;
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");
      if (/^(:\/\/)/.test(newUrl)) {
        return `https${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `https://${newUrl}`;
      }
      return newUrl;
    },
  },
};
</script>
<style scoped>
.proof-link {
  cursor: pointer;
}
.promotionDetailsContainer {
  min-height: 500px;
}
</style>
